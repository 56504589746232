import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';

function ViewAssetPage() {
  const { assetId } = useParams();  // Get asset ID from the URL
  const { state } = useLocation();  // Get the passed state (asset data)
  const asset = state?.asset;  // Extract asset data from the passed state
  const [rentals, setRentals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRentals = async () => {
      try {
        const response = await fetch(`https://api.redlabeltrucking.com/assets/history/${assetId}`);
        const data = await response.json();
        setRentals(data);
      } catch (error) {
        console.error('Error fetching rental data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRentals();
  }, [assetId]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" sx={{ marginTop: 4, marginBottom: 2, marginLeft: 2 }} gutterBottom>
        {asset ? asset.u_number : assetId}
      </Typography>

      <Table sx={{ minWidth: 650 }} aria-label="rental history table">
        <TableHead>
          <TableRow>
            <TableCell>Invoice Number</TableCell>
            <TableCell>Rentee Name</TableCell>
            <TableCell>Rental Date</TableCell>
            <TableCell>Lease End Date</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rentals.map((rental) => (
            <TableRow key={rental._id}>
              <TableCell>{rental.invoice_number}</TableCell>
              <TableCell>{rental.rentee ? rental.rentee.name : 'N/A'}</TableCell>
              <TableCell>{new Date(rental.rental_date).toLocaleDateString()}</TableCell>
              <TableCell>{rental.lease_end ? new Date(rental.lease_end).toLocaleDateString() : 'N/A'}</TableCell>
              <TableCell>${rental.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ViewAssetPage;
