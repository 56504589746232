import React, { useEffect, useState } from 'react';
import { Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import letterhead from '../assets/letterhead.png';  // Webpack will resolve this
import { useNavigate } from 'react-router-dom';

function CompletedRentalsTable() {
  const navigate = useNavigate();  // Initialize useNavigate
  const [rentals, setRentals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');  // State to store search query
  const [startDate, setStartDate] = useState(null);  // Start date for date range filter
  const [endDate, setEndDate] = useState(null);  // End date for date range filter

  // Fetch completed rentals from the backend
  useEffect(() => {
    fetch('https://api.redlabeltrucking.com/rentals/completed')  // Replace with your actual API endpoint
      .then(response => response.json())
      .then(data => {
        setRentals(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching completed rentals:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  const generateInvoicePDF = (data) => {
    // PDF generation logic remains unchanged...
  };

  // Filter rentals based on the search query and date range (by customer name, asset u_number, or invoice number)
  const filteredRentals = rentals.filter(rental => {
    const customerName = rental.rentee?.name?.toLowerCase() || 'unknown customer';
    const assetNumber = rental.asset?.u_number?.toLowerCase() || '';
    const invoiceNumber = rental.invoice_number?.toString().toLowerCase();

    // Check if the rental falls within the selected date range
    const leaseEndDate = new Date(rental.lease_end);
    const isWithinDateRange = (
      (!startDate || leaseEndDate >= new Date(startDate)) &&
      (!endDate || leaseEndDate <= new Date(endDate))
    );

    // Check if the search query matches the customer name, asset number, or invoice number
    const matchesSearchQuery = (
      customerName.includes(searchQuery.toLowerCase()) || 
      assetNumber.includes(searchQuery.toLowerCase()) || 
      invoiceNumber.includes(searchQuery.toLowerCase())
    );

    // Return rentals that match both the search query and the date range
    return matchesSearchQuery && isWithinDateRange;
  });

  // Step 1: Group rentals by rentee/customer
  const groupedRentals = filteredRentals.reduce((acc, rental) => {
    const customerName = rental.rentee?.name || 'Unknown Customer';  // Group by the customer name (rentee), handle null/undefined rentee
    if (!acc[customerName]) {
      acc[customerName] = [];
    }
    acc[customerName].push(rental);
    return acc;
  }, {});

  // Step 2: Sort rentals within each group by lease_end date (descending order)
  Object.keys(groupedRentals).forEach(customerName => {
    groupedRentals[customerName].sort((a, b) => new Date(b.lease_end) - new Date(a.lease_end));
  });

  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" sx={{ marginTop: 4, marginBottom: 2, marginLeft: 2 }} gutterBottom>
        Completed Rentals
      </Typography>

      {/* Search bar to filter rentals */}
      <TextField
        label="Search by Customer Name, Asset U Number, or Invoice Number"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}  // Update search query state
      />

      {/* Date pickers for filtering by date range */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px', marginBottom: '16px' }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} sx={{ minWidth: '200px' }} />}  // Set width for the picker
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} sx={{ minWidth: '200px' }} />}  // Set width for the picker
          />
        </div>

      </LocalizationProvider>

      {/* Iterate over each customer group */}
      {Object.keys(groupedRentals).map((customerName) => (
        <div key={customerName}>
          {/* Customer Name with Count */}
          <Typography variant="h6" sx={{ marginTop: 4, marginBottom: 2, marginLeft: 2 }}>
            {customerName} ({groupedRentals[customerName].length})  {/* Render Customer Name with rental count */}
          </Typography>

          {/* Table for Rentals under each customer */}
          <Table sx={{ minWidth: 650 }} aria-label="completed rentals table">
            <TableHead>
              <TableRow>
                <TableCell>Invoice Number</TableCell>
                <TableCell>Asset U Number</TableCell>
                <TableCell>Rental Date</TableCell>
                <TableCell>Lease End Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedRentals[customerName].map((rental) => (
                <TableRow key={rental._id}>
                  <TableCell>{rental.invoice_number}</TableCell>
                  <TableCell onClick={() => navigate(`/assets/view/${rental.asset._id}`, { state: { asset: rental.asset } })}>{rental.asset ? rental.asset.u_number : 'N/A'}</TableCell>
                  <TableCell>{new Date(rental.rental_date).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(rental.lease_end).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => generateInvoicePDF(rental)}
                    >
                      Generate PDF
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}
    </TableContainer>
  );
}

export default CompletedRentalsTable;
