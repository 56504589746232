import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Button, Container, Typography, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';  // Import DatePicker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';  // Import LocalizationProvider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';  // Import the dayjs adapter for date management
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import { createFilterOptions } from '@mui/material/Autocomplete';  // Import createFilterOptions from Material-UI
import dayjs from 'dayjs';  // For date formatting

function CreateRentalPage() {
  // State for customers, assets, selected customer, selected asset, rental date, and price
  const [customers, setCustomers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [rentalDate, setRentalDate] = useState(dayjs()); // Default to today's date
  const [price, setPrice] = useState(''); // State for price

  // Fetch customers and assets from the backend when the component mounts
  useEffect(() => {
    // Fetch customers
    fetch('https://api.redlabeltrucking.com/customers')
      .then((response) => response.json())
      .then((data) => setCustomers(data))
      .catch((error) => console.error('Error fetching customers:', error));

    // Fetch assets
    fetch('https://api.redlabeltrucking.com/assets')
      .then((response) => response.json())
      .then((data) => setAssets(data))
      .catch((error) => console.error('Error fetching assets:', error));
  }, []);

  // Custom filtering using createFilterOptions from MUI
  const customerFilterOptions = createFilterOptions({
    stringify: (option) => option.name, // Filter by the customer name
  });

  const assetFilterOptions = createFilterOptions({
    stringify: (option) => option.u_number, // Filter by the asset's u_number
  });

  // Handler for submitting the form (in this case, creating the rental)
  const handleSubmit = () => {
    if (!selectedCustomer || !selectedAsset || !rentalDate || price === '') {
      alert('Please select a customer, an asset, a rental date, and enter a price.');
      return;
    }

    // Create rental object
    const rental = {
      customer: selectedCustomer._id, // Use the customer's ID
      asset: selectedAsset._id,       // Use the asset's ID
      rental_date: rentalDate.toISOString(), // Convert rental date to ISO format
      price: parseFloat(price),       // Convert price to a number
    };

    // Send the rental data to the backend
    fetch('https://api.redlabeltrucking.com/rentals', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(rental),
    })
      .then((response) => response.json())
      .then((data) => {
        alert('Rental created successfully!');
        // Optionally reset the form here
      })
      .catch((error) => console.error('Error creating rental:', error));
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Typography variant="h4" gutterBottom>Create a New Rental</Typography>

      {/* Link to go back to the Main Page */}
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button variant="outlined" color="secondary" sx={{ marginBottom: 2 }}>
          Back to Main Page
        </Button>
      </Link>

      {/* Use Grid to align elements side by side */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {/* Autocomplete for selecting a customer */}
          <Autocomplete
            options={customers}
            getOptionLabel={(customer) => customer.name || ""}  // Display and filter by customer name
            filterOptions={customerFilterOptions}  // Use custom filterOptions for case-insensitive matching
            value={selectedCustomer}
            onChange={(event, newValue) => setSelectedCustomer(newValue)}
            renderInput={(params) => <TextField {...params} label="Select Customer" variant="outlined" fullWidth />}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Autocomplete for selecting an asset */}
          <Autocomplete
            options={assets}
            getOptionLabel={(asset) => asset.u_number || ""}  // Display and filter by u_number
            filterOptions={assetFilterOptions}  // Use custom filterOptions for case-insensitive matching
            value={selectedAsset}
            onChange={(event, newValue) => setSelectedAsset(newValue)}
            renderInput={(params) => <TextField {...params} label="Select Asset" variant="outlined" fullWidth />}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* DatePicker for selecting the rental date */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Rental Date"
              value={rentalDate}
              onChange={(newValue) => setRentalDate(newValue)}  // Update state with selected date
              renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Number field for entering the price */}
          <TextField
            label="Enter Price"
            type="number"
            variant="outlined"
            value={price}
            onChange={(event) => setPrice(event.target.value)}  // Update state with entered price
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          {/* Button to submit the form */}
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
            Create Rental
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CreateRentalPage;
