import React, { useEffect, useState } from 'react';
import { Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CustomersTable() {
  const navigate = useNavigate();  // Initialize useNavigate
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);  // For displaying filtered customers
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');  // Search term state

  useEffect(() => {
    // Fetch the customers from the backend API
    fetch('https://api.redlabeltrucking.com/customers')  // Replace with your actual API endpoint
      .then(response => response.json())
      .then(data => {
        setCustomers(data);
        setFilteredCustomers(data);  // Initially show all customers
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching customers:', error);
        setLoading(false);
      });
  }, []);

  // Handle search term input
  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    // Filter customers based on search term
    const filtered = customers.filter(customer =>
      customer.name.toLowerCase().includes(value)
    );
    setFilteredCustomers(filtered);  // Update filtered customers
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" sx={{ marginTop: 4, marginBottom: 2, marginLeft: 2 }} gutterBottom>
        Customers
      </Typography>
      
      {/* Search Bar */}
      <TextField
        label="Search Customers"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}  // Adds margin below the search bar
        value={searchTerm}
        onChange={handleSearchChange}  // Update search term
      />

      <Table sx={{ minWidth: 650 }} aria-label="customers table">
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredCustomers.map((customer) => (
            <TableRow key={customer._id}>
              <TableCell component="th" scope="row">
                {customer.name}
              </TableCell>
              <TableCell align="right">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/customers/edit/${customer._id}`)}  // Navigate to Edit page
                  >
                    Edit
                </Button>
                &nbsp; {/* Add space between buttons */}
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate(`/customers/view/${customer._id}`, { state: { customer } })}  // Pass customerName in the state
                  >
                    View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CustomersTable;
