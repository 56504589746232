import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, CircularProgress, Paper, Typography, MenuItem, FormControl, Select, InputLabel } from '@mui/material';

const provinces = [
  { abb: "AB", name: "Alberta" },
  { abb: "BC", name: "British Columbia" },
  { abb: "MB", name: "Manitoba" },
  { abb: "NB", name: "New Brunswick" },
  { abb: "NL", name: "Newfoundland and Labrador" },
  { abb: "NS", name: "Nova Scotia" },
  { abb: "ON", name: "Ontario" },
  { abb: "PE", name: "Prince Edward Island" },
  { abb: "QC", name: "Quebec" },
  { abb: "SK", name: "Saskatchewan" },
  { abb: "NT", name: "Northwest Territories" },
  { abb: "NU", name: "Nunavut" },
  { abb: "YT", name: "Yukon" }
];

function EditCustomerPage() {
  const { id } = useParams();  // Get the customer ID from the URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    provinceOrState: '',
    postal_code: '',
    c_number: '',
    country: ''
  });

  // Fetch customer data when the component mounts
  useEffect(() => {
    fetch(`https://api.redlabeltrucking.com/customers/${id}`)  // Replace with actual API endpoint
      .then(response => response.json())
      .then(data => {
        setFormData({
          name: data.name,
          address: data.address,
          city: data.city,
          provinceOrState: data.provinceOrState.abb,  // Pre-fill province/state with abbreviation
          postal_code: data.postal_code,
          c_number: data.c_number,
          country: data.country
        });
        setLoading(false);
      })
      .catch(error => console.error('Error fetching customer data:', error));
  }, [id]);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle province dropdown change
  const handleProvinceChange = (event) => {
    setFormData({
      ...formData,
      provinceOrState: event.target.value  // Update with selected province abbreviation
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Send updated data to the backend
    fetch(`https://api.redlabeltrucking.com/customers/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (response.ok) {
          alert('Customer updated successfully!');
          navigate('/customers');  // Navigate back to the customer list after successful update
        } else {
          alert('Error updating customer.');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error updating customer:', error);
        setLoading(false);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Paper sx={{ padding: 4, maxWidth: 600, margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Edit Customer
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Customer Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="City"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        
        {/* Province or State Dropdown */}
        <FormControl fullWidth margin="normal" required>
          <InputLabel>Province or State</InputLabel>
          <Select
            value={formData.provinceOrState}
            onChange={handleProvinceChange}
            label="Province or State"
          >
            {provinces.map((province) => (
              <MenuItem key={province.abb} value={province.abb}>
                {province.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Postal Code"
          name="postal_code"
          value={formData.postal_code}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Contact Number"
          name="c_number"
          value={formData.c_number}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Country"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
        >
          Save Changes
        </Button>
      </form>
    </Paper>
  );
}

export default EditCustomerPage;
