import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, TextField, Typography } from '@mui/material';
import Cookies from 'js-cookie';  // You can use vanilla JS if you prefer

const PASSWORD = 'lindt-chocolate!';  // Replace with your actual password

const PasswordModal = ({ setAuthenticated }) => {
  const [open, setOpen] = useState(true);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handlePasswordSubmit = () => {
    if (password === PASSWORD) {
      // Correct password, set cookie
      Cookies.set('authenticated', 'true', { expires: 7 });  // Cookie expires in 7 days
      setAuthenticated(true);
      setOpen(false);  // Close the modal
    } else {
      setError(true);  // Show an error message
    }
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" component="h2">
          Enter Password
        </Typography>
        <TextField
          type="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          error={error}
          helperText={error ? 'Incorrect password' : ''}
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handlePasswordSubmit}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default PasswordModal;
