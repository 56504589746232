import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TextField, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button, Typography, Modal, Box, List, ListItem, ListItemText } from '@mui/material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import letterhead from '../assets/letterhead.png';  // Webpack will resolve this
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function RentalsTable() {
  const navigate = useNavigate();  // Initialize useNavigate
  const [rentals, setRentals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);  // State to handle modal open/close
  const [selectedRental, setSelectedRental] = useState(null);  // State to store the selected rental
  const [leaseEndDates, setLeaseEndDates] = useState({});  // Store selected lease_end dates
  const [searchTerm, setSearchTerm] = useState('');  // Search term state

  const fetchRentals = () => {
      setLoading(true);  // Start loading spinner
      fetch('https://api.redlabeltrucking.com/rentals/current')  // Your API endpoint for fetching rentals
        .then(response => response.json())
        .then(data => {
          setRentals(data);
          setLoading(false);  // Stop loading spinner
        })
        .catch(error => {
          console.error('Error fetching rentals:', error);
          setLoading(false);
        });
    };

    // Initial fetch when the component mounts
    useEffect(() => {
      fetchRentals();
    }, []);

  if (loading) {
    return <CircularProgress />;
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Function to handle the rollover of the rental
  const rollover = async (rental) => {
    const newRolloverDate = dayjs(rental.rollover_date).add(30, 'day').toISOString();  // Advance rollover_date by 30 days
    const newRolloverNumber = rental.rollover_number + 1;  // Increment rollover_number by 1

    // Create a history entry from the current values
    const historyEntry = {
      price: rental.price,
      rollover_date: rental.rollover_date,
      invoice_number: rental.invoice_number,
      rollover_number: rental.rollover_number,
    };

    // Create a new rental object with updated values
    const updatedRental = {
      ...rental,
      asset: rental.asset._id,  // Send only the asset's _id
      rentee: rental.rentee._id,  // Send only the rentee's _id
      rollover_date: newRolloverDate,
      rollover_number: newRolloverNumber,
      history: [...rental.history, historyEntry],  // Add the new history entry to the history array
    };

    try {
      // Send PUT request to update the rental in the database
      const response = await fetch(`https://api.redlabeltrucking.com/rentals/${rental._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedRental),
      });

      if (response.ok) {
        alert('Rental successfully rolled over!');
        generateInvoicePDF(rental);
        fetchRentals();
      } else {
        alert('Error occurred while rolling over the rental.');
      }
    } catch (error) {
      console.error('Error updating rental:', error);
      alert('Error occurred while rolling over the rental.');
    }
  };

   const handleDateChange = (rentalId, newDate) => {
    setLeaseEndDates({ ...leaseEndDates, [rentalId]: newDate });
  };

  // Function to mark the lease as ended
  const leaseEnd = async (rental) => {
    const lease_end = leaseEndDates[rental._id];

    if (!lease_end) {
      alert('Please select a lease end date.');
      return;
    }

    // Create an updated rental object
    const updatedRental = {
      ...rental,
      asset: rental.asset._id,  // Send only the asset's _id
      rentee: rental.rentee._id,  // Send only the rentee's _id
      lease_end: lease_end.toISOString(),  // Save the selected date as ISO string
      complete: { $numberInt: "1" },
    };

    const documentPDF = {
      ...rental,
      lease_end: lease_end.toISOString(),  // Save the selected date as ISO string
      complete: { $numberInt: "1" },
    };

    try {
      // Send PUT request to update the rental in the database
      const response = await fetch(`https://api.redlabeltrucking.com/rentals/${rental._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedRental),
      });

      if (response.ok) {
        alert('Lease ended successfully!');
        setLeaseEndDates({ ...leaseEndDates, [rental._id]: null });  // Reset the date picker
        generateInvoicePDF(documentPDF);
        fetchRentals();
      } else {
        alert('Error occurred while ending the lease.');
      }
    } catch (error) {
      console.error('Error ending the lease:', error);
      alert('Error occurred while ending the lease.');
    }
  };

  // Function to generate PDF for a rental invoice
  const generateInvoicePDF = (data) => {
    const doc = new jsPDF();
    var img = new Image();
    img.src = letterhead;
    doc.addImage(img, 'png', 12, 30, 185, 25);
    let bodyArray = [];
    let customerObject = data.rentee;

    doc.setFontSize(20);
    doc.text('Rental Invoice', 15, 65);
    doc.setFontSize(14);
    doc.text(data.invoice_number + "-" + data.rollover_number, 15, 72);

    doc.setFontSize(16);
    doc.text(customerObject.name, 15, 85);
    doc.setFontSize(10);

    let price = "$" + parseFloat(data.price).toFixed(2);
    let rollover_date = moment(data.rollover_date).format("MMM-DD-YYYY");
    let previous_date = moment(data.rollover_date).subtract(30, 'days').format("MMM-DD-YYYY");

    if (data.lease_end || data.complete === 1) {
      doc.text("LEASE END", 40, 72);
      doc.text("This lease has been ended.", 15, 225);

      let prev = moment(previous_date);
      let lease_end = moment(data.lease_end);
      let february = lease_end.isBetween('2021-02-01', '2021-02-28');

      if (february) {
        lease_end = moment(data.lease_end).add(1, 'days');
      }

      let difference = Math.trunc(moment.duration(prev.diff(lease_end)).asDays());
      rollover_date = lease_end.format("MMM-DD-YYYY");

      if (Math.abs(difference) < 15) {
        data.price = Math.abs(difference) * 35;
        price = "$" + parseFloat(data.price).toFixed(2);
      }
    }

    bodyArray.push([
      { content: previous_date, colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', minCellHeight: 80 } },
      { content: rollover_date, colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', minCellHeight: 80 } },
      { content: price, colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', minCellHeight: 80 } }
    ]);

    let total = data.price;
    let totalTax = (data.price * 0.05);
    let totalWithTax = total * 1.05;
    let totalFloat = parseFloat(totalWithTax.toString()).toFixed(2);
    let totalString = "$" + totalFloat.toString();
    let totalTaxFloat = parseFloat(totalTax.toString()).toFixed(2);
    let totalTaxString = "$" + totalTaxFloat.toString();

    bodyArray.push([{ content: 'GST', colSpan: 2, styles: { halign: 'right', fontStyle: 'bold' } }, { content: totalTaxString, colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } }]);
    bodyArray.push([{ content: 'TOTAL', colSpan: 2, styles: { halign: 'right', fontStyle: 'bold' } }, { content: totalString, colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } }]);

    // Generate PDF tables with autoTable
    autoTable(doc, {
      theme: 'grid',
      margin: { left: 80 },
      startY: 60,
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { minCellHeight: 10 },
      head: [['Name', 'Address']],
      body: [[customerObject.name, `${customerObject.address} ${customerObject.city} ${customerObject.provinceOrState.abb} ${customerObject.postal_code}`, data.po_number, data.description]]
    });

    autoTable(doc, {
      theme: 'grid',
      startY: 80,
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { minCellHeight: 10 },
      head: [['Unit', 'Type', 'P/O', 'Information']],
      body: [[data.asset.u_number, data.asset.type, data.po_number, data.description]]
    });

    autoTable(doc, {
      theme: 'grid',
      startY: 100,
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { minCellHeight: 10 },
      head: [['Period Start', 'Period End', 'Price']],
      body: bodyArray,
    });

    doc.text("Please include the invoice number on the cheque when sending payments. Thank you.", 15, 215);

    doc.save(`${data.invoice_number}-${data.rollover_number}.pdf`);
  };

  const handleGeneratePDF = (rental, historyItem) => {
    // Create a copy of the rental object
    const updatedRental = { ...rental };

    // Update the rental object with the selected history entry's rollover number and date
    updatedRental.rollover_number = historyItem.rollover_number;
    updatedRental.rollover_date = historyItem.rollover_date;

    // Pass the updated rental object to the generateInvoicePDF function
    generateInvoicePDF(updatedRental);
  };


  // Function to handle modal open
  const handleOpen = (rental) => {
    setSelectedRental(rental);  // Store the entire rental object
    setOpen(true);
  };

  // Function to handle modal close
  const handleClose = () => setOpen(false);

  // Modal style
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 600,  // Fixed height for the modal
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'hidden',  // Prevents the modal content from overflowing
  };


  const filteredRentals = rentals.filter(rental => {
    const customerName = rental.rentee?.name?.toLowerCase() || '';
    const assetNumber = rental.asset?.u_number?.toLowerCase() || '';
    const invoiceNumber = rental.invoice_number?.toString().toLowerCase();

    return (
      customerName.includes(searchTerm) || 
      assetNumber.includes(searchTerm) || 
      invoiceNumber.includes(searchTerm)
    );
  });

  // Group rentals by customer name after filtering
  const groupedRentals = filteredRentals.reduce((acc, rental) => {
    const customerName = rental.rentee?.name || 'Unknown Customer';
    if (!acc[customerName]) {
      acc[customerName] = [];
    }
    acc[customerName].push(rental);
    return acc;
  }, {});

  const currentDate = new Date(); // Get current date

  return (
    <TableContainer component={Paper}>
    <TextField
            label="Search by Customer Name, Asset U Number, or Invoice Number"
            variant="outlined"
            fullWidth
            sx={{ marginBottom: 2, marginTop: 2 }}
            value={searchTerm}
            onChange={handleSearchChange}  // Update search term state
          />
      {Object.keys(groupedRentals).map((customerName) => (
        <div key={customerName}>
          <Typography variant="h4" sx={{ marginTop: 4, marginBottom: 2, marginLeft: 2 }} gutterBottom>
            {customerName} ({groupedRentals[customerName].length}) {/* Render Customer Name as Header */}
          </Typography>

          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan="3">Invoice Number</TableCell>
                <TableCell align="right">Asset</TableCell>
                <TableCell align="right">Rollover</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="center" colSpan="4">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Sort the rentals by rollover_date in ascending order */}
              {groupedRentals[customerName]
                .sort((a, b) => new Date(a.rollover_date) - new Date(b.rollover_date)) // Sorting by date ascending
                .map((rental) => {
                  const rolloverDate = new Date(rental.rollover_date);
                  const isDisabled = rolloverDate.getTime() > currentDate.getTime(); // Disable if rollover_date is before today

                  return (
                    <TableRow key={rental._id}>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">
                            {rental ? `${rental.invoice_number} - ${rental.rollover_number}` : 'N/A'}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            sx={{ color: 'gray' }}  // Make rental_date gray
                          >
                            {new Date(rental.rental_date).toLocaleDateString()}
                          </Typography>
                        
                      </TableCell>
                      <TableCell align="right">

                        {/* Button to open modal and show history */}
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{  }}
                          onClick={() => handleOpen(rental)}
                        >
                          View History
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(`/rentals/edit/${rental._id}`)}  // Navigate to Edit page
                          >
                            Edit
                        </Button>
                      </TableCell>
                      <TableCell 
                        align="right" 
                        onClick={() => navigate(`/assets/view/${rental.asset._id}`, { state: { asset: rental.asset } })}
                      >
                        {rental.asset ? rental.asset.u_number : 'N/A'}
                      </TableCell>
                      <TableCell align="right">{new Date(rental.rollover_date).toLocaleDateString()}</TableCell>
                      <TableCell align="right">${rental.price}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => rollover(rental)}
                          disabled={isDisabled} // Conditionally disable the button
                        >
                          Rollover
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Lease End Date"
                            value={leaseEndDates[rental._id] || null}
                            onChange={(newDate) => handleDateChange(rental._id, newDate)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </TableCell>

                      {/* Lease End Button */}
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => leaseEnd(rental)}
                        >
                          Lease End
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      ))}

      {/* Modal to show history */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          {selectedRental && (
            <>
              <Typography variant="h6" component="h2">
                Invoice Number: {selectedRental.invoice_number}
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Rollover History
              </Typography>

              {/* Scrollable List */}
              <Box sx={{ maxHeight: '550px', overflowY: 'auto' }}>  {/* Max height and scroll for the list */}
                <List>
                  {selectedRental.history && selectedRental.history.length > 0 ? (
                    selectedRental.history.map((historyItem, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={`${selectedRental.invoice_number} - ${historyItem.rollover_number}`}
                          secondary={`Date: ${new Date(historyItem.rollover_date).toLocaleDateString()}`}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleGeneratePDF(selectedRental, historyItem)}
                        >
                          Generate PDF
                        </Button>
                      </ListItem>
                    ))
                  ) : (
                    <Typography>No history available</Typography>
                  )}
                </List>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </TableContainer>
  );
}

export default RentalsTable;
