import React from 'react';
import { Drawer, List, ListItem, ListItemText, CssBaseline, AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';  // For managing cookies
import { useNavigate } from 'react-router-dom';  // For redirecting after logout

const drawerWidth = 200;  // Adjust if necessary

function SidebarLayout({ children }) {
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove('authenticated');  // Remove the authentication cookie
    navigate('/');  // Redirect to the home page or anywhere else, triggering the login modal
    window.location.reload();  // Optionally refresh the page to force the modal to show up
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      {/* AppBar for top navigation */}
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            RL Management Console
          </Typography>
          <Button color="inherit" sx={{ marginLeft: 'auto' }} onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <List>
          {/* Rentals Section */}
          <Typography variant="h6" sx={{ paddingLeft: 2, paddingTop: 1 }}>
            Rentals
          </Typography>
          <ListItem button component={Link} to="/rentals">
            <ListItemText primary="List of Rentals" />
          </ListItem>
          <ListItem button component={Link} to="/completed-rentals">
            <ListItemText primary="List of Completed Rentals" />
          </ListItem>
          <ListItem button component={Link} to="/create-rental">
            <ListItemText primary="Create New Rental" />
          </ListItem>

          {/* Assets Section */}
          <Typography variant="h6" sx={{ paddingLeft: 2, paddingTop: 2 }}>
            Assets
          </Typography>
          <ListItem button component={Link} to="/assets">
            <ListItemText primary="List of Assets" />
          </ListItem>
          <ListItem button component={Link} to="/create-asset">
            <ListItemText primary="Create New Asset" />
          </ListItem>

          {/* Customers Section */}
          <Typography variant="h6" sx={{ paddingLeft: 2, paddingTop: 2 }}>
            Customers
          </Typography>
          <ListItem button component={Link} to="/customers">
            <ListItemText primary="List of Customers" />
          </ListItem>
          <ListItem button component={Link} to="/create-customer">
            <ListItemText primary="Create New Customer" />
          </ListItem>
        </List>
      </Drawer>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          marginLeft: `0px`,  // Remove or minimize margin between sidebar and content
        }}
      >
        <Toolbar />
        {children}  {/* This renders the page content */}
      </Box>
    </Box>
  );
}

export default SidebarLayout;
