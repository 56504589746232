import React, { useEffect, useState } from 'react';
import { Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function AssetsTable() {
  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch the assets from the backend API
    fetch('https://api.redlabeltrucking.com/assets')  // Replace with your actual API endpoint
      .then(response => response.json())
      .then(data => {
        setAssets(data);
        setFilteredAssets(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching assets:', error);
        setLoading(false);
      });
  }, []);

  // Handle search term input
  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = assets.filter(asset =>
      asset.u_number.toLowerCase().includes(value)
    );
    setFilteredAssets(filtered);
  };

  const groupedAssets = filteredAssets.reduce((acc, asset) => {
    const uNumber = asset.u_number || '';
    let groupName;

    if (uNumber.startsWith('OT')) {
      groupName = 'Ocean Trailer';
    } else if (uNumber.startsWith('TW')) {
      groupName = 'Trailer Wizards';
    } else {
      groupName = 'Red Label Trucking';
    }

    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(asset);
    return acc;
  }, {});

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" sx={{ marginTop: 4, marginBottom: 2, marginLeft: 2 }} gutterBottom>
        Assets
      </Typography>
      
      <TextField
        label="Search by U Number"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {Object.keys(groupedAssets).map((groupName) => (
        <div key={groupName}>
          <Typography variant="h6" sx={{ marginTop: 4, marginBottom: 2, marginLeft: 2 }}>
            {groupName} ({groupedAssets[groupName].length})
          </Typography>

          <Table sx={{ minWidth: 650 }} aria-label="assets table">
            <TableHead>
              <TableRow>
                <TableCell>Asset U Number</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedAssets[groupName].map((asset) => (
                <TableRow key={asset._id}>
                  <TableCell component="th" scope="row">
                    {asset.u_number}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(`/assets/edit/${asset._id}`)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ marginLeft: 2 }}
                      onClick={() => navigate(`/assets/view/${asset._id}`, { state: { asset } })}  // Pass asset data
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}
    </TableContainer>
  );
}

export default AssetsTable;
