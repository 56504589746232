import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function ViewCustomerPage() {
  const { state } = useLocation();  // Get the passed state (asset data)
  const customer = state?.customer;  // Extract asset data from the passed state
  const { customerId } = useParams();  // Retrieve customer ID from the URL
  const [rentals, setRentals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch rentals by customer ID from the backend
    fetch(`https://api.redlabeltrucking.com/rentals/customer/${customerId}`)
      .then(response => response.json())
      .then(data => {
        setRentals(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching rentals:', error);
        setLoading(false);
      });
  }, [customerId]);

  if (loading) {
    return <CircularProgress />;
  }

  // Separate current and completed rentals
  const currentRentals = rentals.filter(rental => !rental.lease_end);
  const completedRentals = rentals.filter(rental => rental.lease_end);

  // Sort completed rentals by lease_end date (descending)
  completedRentals.sort((a, b) => new Date(b.lease_end) - new Date(a.lease_end));

  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" sx={{ marginTop: 4, marginBottom: 2, marginLeft: 2 }} gutterBottom>
        {customer.name || 'Unknown Customer'}
      </Typography>

      {/* Current Rentals */}
      <Typography variant="h6" sx={{ marginTop: 2, marginLeft: 2 }}>
        Current Rentals ({currentRentals.length})
      </Typography>
      <Table sx={{ minWidth: 650 }} aria-label="current rentals table">
        <TableHead>
          <TableRow>
            <TableCell>Invoice Number</TableCell>
            <TableCell>Asset U Number</TableCell>
            <TableCell>Rental Date</TableCell>
            <TableCell>Rollover Date</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentRentals.map((rental) => (
            <TableRow key={rental._id}>
              <TableCell>{rental.invoice_number}</TableCell>
              <TableCell>{rental.asset ? rental.asset.u_number : 'N/A'}</TableCell>
              <TableCell>{new Date(rental.rental_date).toLocaleDateString()}</TableCell>
              <TableCell>{new Date(rental.rollover_date).toLocaleDateString()}</TableCell>
              <TableCell>${rental.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Completed Rentals */}
      <Typography variant="h6" sx={{ marginTop: 4, marginLeft: 2 }}>
        Completed Rentals ({completedRentals.length})
      </Typography>
      <Table sx={{ minWidth: 650 }} aria-label="completed rentals table">
        <TableHead>
          <TableRow>
            <TableCell>Invoice Number</TableCell>
            <TableCell>Asset U Number</TableCell>
            <TableCell>Rental Date</TableCell>
            <TableCell>Lease End Date</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {completedRentals.map((rental) => (
            <TableRow key={rental._id}>
              <TableCell>{rental.invoice_number}</TableCell>
              <TableCell>{rental.asset ? rental.asset.u_number : 'N/A'}</TableCell>
              <TableCell>{new Date(rental.rental_date).toLocaleDateString()}</TableCell>
              <TableCell>{new Date(rental.lease_end).toLocaleDateString()}</TableCell>
              <TableCell>${rental.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ViewCustomerPage;
