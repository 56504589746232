import React, { useState, useEffect } from 'react';
import { TextField, Button, CircularProgress, MenuItem, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';  // Use useNavigate instead of useHistory
import { Link } from 'react-router-dom';

function EditRentalPage() {
  const { id } = useParams();  // Get rental ID from URL params
  const navigate = useNavigate();  // Initialize useNavigate instead of useHistory
  const [rental, setRental] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    // Fetch rental details by ID
    fetch(`https://api.redlabeltrucking.com/rentals/${id}`)
      .then(response => response.json())
      .then(data => {
        setRental(data);
        setLoading(false);
      })
      .catch(error => console.error('Error fetching rental details:', error));

    // Fetch assets and customers for dropdown fields
    fetch('https://api.redlabeltrucking.com/assets')
      .then(response => response.json())
      .then(data => setAssets(data))
      .catch(error => console.error('Error fetching assets:', error));

    fetch('https://api.redlabeltrucking.com/customers')
      .then(response => response.json())
      .then(data => setCustomers(data))
      .catch(error => console.error('Error fetching customers:', error));
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRental({ ...rental, [name]: value });
  };

  const handleSave = () => {
    // Update rental in the database
    fetch(`https://api.redlabeltrucking.com/rentals/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rental),
    })
      .then((response) => {
        if (response.ok) {
          alert('Rental updated successfully!');
          navigate('/rentals');  // Navigate to rentals list page after saving
        } else {
          alert('Error updating rental.');
        }
      })
      .catch((error) => console.error('Error updating rental:', error));
  };

  return (
    <div>
      <Typography variant="h4">Edit Rental</Typography>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button variant="outlined" color="secondary" sx={{ marginBottom: 2 }}>
          Back to Main Page
        </Button>
      </Link>
      <TextField
        label="Invoice Number"
        name="invoice_number"
        value={rental.invoice_number}
        onChange={handleChange}
        fullWidth
        disabled
        margin="normal"
      />

      <TextField
        label="Rollover Number"
        name="rollover_number"
        value={rental.rollover_number}
        onChange={handleChange}
        fullWidth
        disabled
        margin="normal"
      />

      <TextField
        label="Price"
        name="price"
        value={rental.price}
        onChange={handleChange}
        type="number"
        fullWidth
        margin="normal"
      />

      <TextField
        label="Rollover Date"
        name="rollover_date"
        type="date"
        value={new Date(rental.rollover_date).toISOString().split('T')[0]}  // Format date for input field
        onChange={handleChange}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        select
        label="Asset"
        name="asset"
        value={rental.asset._id}  // Asset ID prefilled
        onChange={(e) => setRental({ ...rental, asset: { _id: e.target.value } })}
        fullWidth
        margin="normal"
      >
        {assets.map((asset) => (
          <MenuItem key={asset._id} value={asset._id}>
            {asset.u_number} - {asset.type}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Customer"
        name="rentee"
        value={rental.rentee._id}  // Customer ID prefilled
        onChange={(e) => setRental({ ...rental, rentee: { _id: e.target.value } })}
        fullWidth
        margin="normal"
      >
        {customers.map((customer) => (
          <MenuItem key={customer._id} value={customer._id}>
            {customer.name}
          </MenuItem>
        ))}
      </TextField>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ mt: 2 }}
      >
        Save Changes
      </Button>
    </div>
  );
}

export default EditRentalPage;
