import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { Link } from 'react-router-dom';

// Canadian provinces with abbreviations and full names
const provinces = [
  { abb: 'AB', name: 'Alberta' },
  { abb: 'BC', name: 'British Columbia' },
  { abb: 'MB', name: 'Manitoba' },
  { abb: 'NB', name: 'New Brunswick' },
  { abb: 'NL', name: 'Newfoundland and Labrador' },
  { abb: 'NS', name: 'Nova Scotia' },
  { abb: 'ON', name: 'Ontario' },
  { abb: 'PE', name: 'Prince Edward Island' },
  { abb: 'QC', name: 'Quebec' },
  { abb: 'SK', name: 'Saskatchewan' },
  { abb: 'NT', name: 'Northwest Territories' },
  { abb: 'NU', name: 'Nunavut' },
  { abb: 'YT', name: 'Yukon' },
];

function CreateCustomerPage() {
  // State for the customer's fields
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [cNumber, setCNumber] = useState('');
  const [nonCustomer, setNonCustomer] = useState(false);

  // Handler for submitting the form
  const handleSubmit = () => {
    // Validate required fields (name, address, city, etc.)
    if (!name || !address || !city || !selectedProvince || !country) {
      alert('Please fill out all required fields.');
      return;
    }

    // Create the customer object
    const newCustomer = {
      name,
      address,
      city,
      provinceOrState: { abb: selectedProvince.abb, name: selectedProvince.name },  // Selected province
      postal_code: postalCode,
      country,
      c_number: cNumber,
      non_customer: nonCustomer,
    };

    // Send the customer data to the backend
    fetch('https://api.redlabeltrucking.com/customers', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newCustomer),
    })
      .then((response) => response.json())
      .then((data) => {
        alert('Customer created successfully!');
        // Optionally reset the form here
      })
      .catch((error) => console.error('Error creating customer:', error));
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Typography variant="h4" gutterBottom>Create a New Customer</Typography>

      {/* Link to go back to the Main Page */}
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button variant="outlined" color="secondary" sx={{ marginBottom: 2 }}>
          Back to Main Page
        </Button>
      </Link>

      {/* Customer Form */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Customer Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Address"
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="City"
            variant="outlined"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            fullWidth
            required
          />
        </Grid>

        {/* Province Dropdown */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="province-select-label">Province/State</InputLabel>
            <Select
              labelId="province-select-label"
              value={selectedProvince}
              onChange={(e) => setSelectedProvince(e.target.value)}
              label="Province/State"
              required
            >
              {provinces.map((province) => (
                <MenuItem key={province.abb} value={province}>
                  {province.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Postal Code"
            variant="outlined"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Country"
            variant="outlined"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Contact Number"
            variant="outlined"
            value={cNumber}
            onChange={(e) => setCNumber(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          {/* Submit Button */}
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
            Create Customer
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CreateCustomerPage;
