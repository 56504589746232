import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';  // Import DatePicker for dates
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';  // Import LocalizationProvider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';  // Adapter for date handling
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';  // For date formatting

function CreateAssetPage() {
  // State for the asset's fields
  const [uNumber, setUNumber] = useState('');
  const [make, setMake] = useState('XXXXXX');
  const [model, setModel] = useState('XXXXXX');
  const [year, setYear] = useState('2010');
  const [vin, setVin] = useState('XXXXXX');
  const [location, setLocation] = useState('');
  const [returnedDate, setReturnedDate] = useState(null);
  const [gvw, setGvw] = useState('46500');
  const [y_reg, setYReg] = useState(0);
  const [iDate, setIDate] = useState(null);
  const [lPlate, setLPlate] = useState('XXXXXX');

  // Handler for submitting the form
  const handleSubmit = () => {
    // Validate required fields (u_number, make, model, vin, etc.)
    if (!uNumber || !make || !model || !vin) {
      alert('Please fill out all required fields.');
      return;
    }

    // Create the asset object
    const newAsset = {
      u_number: uNumber,
      make,
      model,
      year: parseInt(year),
      vin,
      location,
      returned: returnedDate ? returnedDate.toISOString() : null,
      gvw: parseFloat(gvw),
      i_date: iDate ? iDate.toISOString() : null,
      l_plate: lPlate,
    };

    // Send the asset data to the backend
    fetch('https://api.redlabeltrucking.com/assets', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newAsset),
    })
      .then((response) => response.json())
      .then((data) => {
        alert('Asset created successfully!');
        // Optionally reset the form here
      })
      .catch((error) => console.error('Error creating asset:', error));
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Typography variant="h4" gutterBottom>Create a New Asset</Typography>

      {/* Link to go back to the Main Page */}
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button variant="outlined" color="secondary" sx={{ marginBottom: 2 }}>
          Back to Main Page
        </Button>
      </Link>

      {/* Asset Form */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Unit Number (u_number)"
            variant="outlined"
            value={uNumber}
            onChange={(e) => setUNumber(e.target.value)}
            fullWidth
            required
          />
        </Grid>


        <Grid item xs={12}>
          {/* Submit Button */}
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
            Create Asset
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CreateAssetPage;
