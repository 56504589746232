import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SidebarLayout from './components/SidebarLayout'; // Import the layout component
import RentalsTable from './components/RentalsTable';  // Your rentals overview page
import CustomersTable from './components/CustomersTable';  // Your customers overview page
import AssetsTable from './components/AssetsTable';  // Your assets overview page

import CreateRentalPage from './pages/CreateRentalPage';  // Page to create new rental
import CreateAssetPage from './pages/CreateAssetPage';  // Page to create new asset
import CreateCustomerPage from './pages/CreateCustomerPage';  // Page to create new customer
import EditRentalPage from './pages/EditRentalPage';  // Page to edit a rental
import EditCustomerPage from './pages/EditCustomerPage';  // Page to edit a customer
import EditAssetPage from './pages/EditAssetPage';  // Page to edit an asset
import CompletedRentalsTable from './components/CompletedRentalsTable';
import ViewAssetPage from './pages/ViewAssetPage';
import ViewCustomerPage from './pages/ViewCustomerPage';

import PasswordModal from './components/PasswordModal';  // Import the PasswordModal component
import Cookies from 'js-cookie';  // For cookie management

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  // Check if the user is authenticated (i.e., cookie exists)
  useEffect(() => {
    const authCookie = Cookies.get('authenticated');
    if (authCookie === 'true') {
      setAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      {!authenticated ? (
        // Show Password Modal if not authenticated
        <PasswordModal setAuthenticated={setAuthenticated} />
      ) : (
        // If authenticated, show the sidebar and the routes
        <SidebarLayout>
          <Routes>
            {/* Define all your routes inside the layout */}
            <Route path="/rentals" element={<RentalsTable />} />
            <Route path="/completed-rentals" element={<CompletedRentalsTable />} />

            <Route path="/customers" element={<CustomersTable />} />
            <Route path="/assets" element={<AssetsTable />} />

            <Route path="/create-rental" element={<CreateRentalPage />} />
            <Route path="/create-asset" element={<CreateAssetPage />} />
            <Route path="/create-customer" element={<CreateCustomerPage />} />
            <Route path="/rentals/edit/:id" element={<EditRentalPage />} />
            <Route path="/customers/edit/:id" element={<EditCustomerPage />} />
            <Route path="/assets/edit/:id" element={<EditAssetPage />} />
            <Route path="/assets/view/:assetId" element={<ViewAssetPage />} />
            <Route path="/customers/view/:customerId" element={<ViewCustomerPage />} />

            {/* Redirect to /rentals by default */}
            <Route path="*" element={<RentalsTable />} />
          </Routes>
        </SidebarLayout>
      )}
    </Router>
  );
}

export default App;
