import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, CircularProgress, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';  // For handling date formatting

function EditAssetPage() {
  const { id } = useParams();  // Get the asset ID from the URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    u_number: '',
    make: '',
    model: '',
    year: '',
    type: '',
    vin: '',
    l_plate: '',
    y_reg: '',
    assetModel: '',
    gvw: '',
    i_date: '',  // Assuming this is a date field
  });

  // Fetch asset data when the component mounts
  useEffect(() => {
    fetch(`https://api.redlabeltrucking.com/assets/${id}`)  // Replace with actual API endpoint
      .then(response => response.json())
      .then(data => {
        setFormData({
          u_number: data.u_number,
          make: data.make,
          model: data.model,
          year: data.year,
          type: data.type,
          vin: data.vin,
          l_plate: data.l_plate,
          y_reg: data.y_reg,
          assetModel: data.assetModel,
          gvw: data.gvw,
          i_date: dayjs(data.i_date).format('YYYY-MM-DD'),  // Format date for input field
        });
        setLoading(false);
      })
      .catch(error => console.error('Error fetching asset data:', error));
  }, [id]);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Send updated data to the backend
    fetch(`https://api.redlabeltrucking.com/assets/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (response.ok) {
          alert('Asset updated successfully!');
          navigate('/assets');  // Navigate back to the asset list after successful update
        } else {
          alert('Error updating asset.');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error updating asset:', error);
        setLoading(false);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Paper sx={{ padding: 4, maxWidth: 600, margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Edit Asset
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Asset U Number"
          name="u_number"
          value={formData.u_number}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Make"
          name="make"
          value={formData.make}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Model"
          name="model"
          value={formData.model}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Year"
          name="year"
          value={formData.year}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Type"
          name="type"
          value={formData.type}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="VIN"
          name="vin"
          value={formData.vin}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="License Plate"
          name="l_plate"
          value={formData.l_plate}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Y Registration"
          name="y_reg"
          value={formData.y_reg}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Asset Model"
          name="assetModel"
          value={formData.assetModel}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="GVW"
          name="gvw"
          value={formData.gvw}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Inspection Date"
          name="i_date"
          type="date"
          value={formData.i_date}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          disabled={loading}  // Disable button while submitting
        >
          {loading ? 'Submitting...' : 'Update Asset'}
        </Button>
      </form>
    </Paper>
  );
}

export default EditAssetPage;
